import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import Home from '../images/icons/home-lg-alt.svg'
import Envelope from '../images/icons/envelope-open-text.svg'


const hero = {
  title: "Qualcosa è andato storto!",
  className: "hero--big hero--auto pb-0",
  subtitle: "Non riusciamo a trovare la pagina che stai cercando, ritorna alla homepage oppure naviga fra altri contenuti!",
  fullTitle: true,
  links: [
    {
      path: '/',
      name: 'Torna alla Homepage',
      icon: <Home />
    },
    {
      path: '/contatti/',
      name: 'Contattaci',
      icon: <Envelope />
    }
  ]
};

const NotFoundPage = ({ data: { home: { heroDesktop, heroMobile } }, location }) => (
  <Layout location={location} hero={{ ...hero, heroDesktop, heroMobile }} >
    <SEO title={`Pagina non trovata`} meta={[{ name: 'robots', content: "noindex, noarchive" }]} />
  </Layout>
);

export default NotFoundPage;

export const query = graphql`{
  home: contentfulPaginaGenerica(contentful_id: { eq: "3BNnhmCC7tDX07aS5USOKA" }) {
    heroDesktop: hero {
      ...HeroContentfulDesktop
    }
    heroMobile {
      ...HeroContentfulMobile
    }
  }
}`